import React from "react";
import $ from "jquery";
// Custom components
import SEO from "../components/seo";
import getCurrentDimension from "../utils/getCurrentDimesion";
import DisinfectionSlider from "../components/DisinfectionSlider";
import FeaturedInSlider from "../components/FeaturedInSlider";
import GetReadySlider from "../components/GetReadySlider";
import GetQuoteModal from "../components/GetQuoteModal";
import ThankYouModal from "../components/ThankYouModal";
import PageContainer from "../components/PageContainer";
import { Link, useTranslation, useI18next } from "gatsby-plugin-react-i18next";

const IndexPage = (props) => {
  const { t } = useTranslation();
  const { language } = useI18next();

  const trustedCompanies = [
    {
      imageUrl: require("../images/truested-companies/anytime.png"),
      alt: "anytime fitness",
      maxWidth: 100,
      maxHeight: 60,
    },
    {
      imageUrl: require("../images/truested-companies/perodua.png"),
      alt: "Perodua",
      maxWidth: 70,
      maxHeight: 60,
    },
    {
      imageUrl: require("../images/truested-companies/PEC.png"),
      alt: "PEC",
      maxWidth: 70,
      maxHeight: 60,
    },
    {
      imageUrl: require("../images/truested-companies/lazada.png"),
      alt: "Lazada",
      maxWidth: 70,
      maxHeight: 60,
    },
    {
      imageUrl: require("../images/truested-companies/allianz.png"),
      alt: "Allianz",
      maxWidth: 100,
      maxHeight: 60,
    },
    {
      imageUrl: require("../images/truested-companies/epson.png"),
      alt: "Epson",
      maxWidth: 70,
      maxHeight: 60,
    },
    {
      imageUrl: require("../images/truested-companies/CapitaLand.png"),
      alt: "Capita Land",
      maxWidth: 100,
      maxHeight: 60,
    },
    {
      imageUrl: require("../images/truested-companies/Sogo.png"),
      alt: "Sogo",
      maxWidth: 70,
      maxHeight: 60,
    },
  ];
  const foggingSrc = require("../images/new/general/spray.png");
  const windowSrc = require("../images/new/general/window.png");
  const folderSrc = require("../images/new/general/folder.png");
  const microscopeSrc = require("../images/new/general/microscope.png");
  const workerSrc = require("../images/general/worker.png");
  const DisinfectionSrc = require("../images/new/general/Disinfection.png");
  const ShopSrc = require("../images/new/general/Shop.png");
  const TestSrc = require("../images/new/general/Test.png");
  const OfficeThingsSrc = require("../images/new/general/OfficeThings.png");
  const circleSrc = require("../images/general/circle.png");
  const shieldLeftSrc = require("../images/general/Shield-left.png");
  const shieldRightSrc = require("../images/general/Shield-right.png");
  //  link redirect
  const commercialUrl = "/advanced";
  const wareHouseUrl = "/warehouse";
  const coatingUrl = "/advanced";
  const handleQuoteRequest = () => $("#getQuoteModal").modal("show");
  const handleRedirect = (link) => {
    props.history.push(link);
  };

  const { width } = getCurrentDimension();
  let descriptionStyles = {
    minHeight: 400,
    borderRadius: 12,
    border: "1px solid #B8D2F5",
    maxWidth: "100%",
  };
  if (width >= 768) {
    descriptionStyles = { ...descriptionStyles, maxWidth: "49%" };
  }

  const servicesButtonsData = [
    {
      anchor: "#fogging",
      bgImage: foggingSrc,
      btnText: t("GENERAL.servicesTitle1"),
    },
    {
      anchor: "#coating",
      bgImage: windowSrc,
      btnText: t("GENERAL.servicesTitle2"),
    },
    {
      anchor: "#products",
      bgImage: folderSrc,
      btnText: t("GENERAL.servicesTitle4"),
    },
    {
      anchor: "#testing",
      bgImage: microscopeSrc,
      btnText: t("GENERAL.servicesTitle3"),
    },
  ];

  const ServicesButton = (props) => {
    const { anchor, bgImage, btnText } = props;

    return (
      <a
        href={anchor}
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundRepeat: "no-repeat",
          display: "flex",
          backgroundSize: 'cover',
          borderRadius: 12,
          width: "100%",
          height: 180,
          maxHeight: "180px",
          maxWidth: "255px",
          textDecoration: "none",
        }}
        className="col-lg-3 col-md-6 col-sm-6 services-card-small my-3 mx-0 mx-md-3 mx-lg-0"
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <p
            style={{
              maxWidth: 180,
              fontWeight: 600,
              fontSize: "16px",
              alignSelf: "flex-end",
              textDecoration: "none",
              color: "#000000",
              whiteSpace: "pre-line",
            }}
          >
            {/* {btnText} */}
          </p>
        </div>
      </a>
    );
  };

  const servicesCardData = [
    {
      id: "fogging",
      cardStyle: descriptionStyles,
      imgSrc: DisinfectionSrc,
      cardTitle: t("GENERAL.servicesCardTitle1"),
      cardBody: t("GENERAL.servicesCardBody1"),
      btnText: t("GENERAL.servicesCardCTA1"),
      btnUrl: commercialUrl,
    },
    {
      id: "coating",
      cardStyle: descriptionStyles,
      imgSrc: ShopSrc,
      cardTitle: t("GENERAL.servicesCardTitle2"),
      cardBody: t("GENERAL.servicesCardBody2"),
      btnText: t("GENERAL.servicesCardCTA2"),
      btnUrl: coatingUrl,
    },
    {
      id: "testing",
      cardStyle: descriptionStyles,
      imgSrc: TestSrc,
      cardTitle: t("GENERAL.servicesCardTitle3"),
      cardBody: t("GENERAL.servicesCardBody3"),
      btnText: t("GENERAL.servicesCardCTA3"),
      btnUrl: null,
      handleClick: handleQuoteRequest,
    },
    {
      id: "products",
      cardStyle: descriptionStyles,
      imgSrc: OfficeThingsSrc,
      cardTitle: t("GENERAL.servicesCardTitle4"),
      cardBody: t("GENERAL.servicesCardBody4"),
      btnText: t("GENERAL.servicesCardCTA4"),
      btnUrl: null,
      handleClick: handleQuoteRequest,
    },
  ];

  const ServicesCard = (props) => {
    const {
      id,
      cardStyle,
      imgSrc,
      cardTitle,
      cardBody,
      btnText,
      btnUrl,
      handleClick,
    } = props;

    const buttonUrl = !!btnUrl ? btnUrl : "";
    return (
      <div
        id={id}
        style={cardStyle}
        className="col-11 col-md-6 p-3 mt-3 services-card-large"
      >
        <img alt="disinfection" src={imgSrc} />
        <p
          style={{
            marginTop: 40,
            marginBottom: 30,
            fontWeight: 600,
            fontSize: "15px",
          }}
          className="h4"
        >
          {cardTitle}
        </p>
        <p
          style={{
            fontSize: "16px",
            lineHeight: "28px",
            marginBottom: 25,
          }}
        >
          {cardBody}
        </p>
        <Link
          style={{
            borderRadius: 98,
            fontWeight: "bold",
            fontSize: 16,
            lineHeight: "20px",
            position: "absolute",
            bottom: 32,
          }}
          to={buttonUrl}
          language={language}
          onClick={handleClick}
          type="button"
          className="btn btn-primary btn-lg CTA-secondary d-none d-md-block"
        >
          {btnText}
        </Link>
        <a
          type="button"
          style={{ fontWeight: "bold", fontSize: 16 }}
          className="btn btn-primary CTA-secondary d-block d-md-none"
          to={buttonUrl}
          language={language}
          onClick={handleClick}
        >
          {btnText}
        </a>
      </div>
    );
  };

  return (
    <PageContainer>
      <SEO title="COVID-19 Professional Disinfection Services for Home and Office" />
      <div>
        <section>
          <div className="d-block d-lg-none">
            <GetReadySlider />
          </div>
          {/* // UVP */}
          <div className="container-fluid">
            <div
              className="col-4 d-none d-lg-block"
              style={{
                position: "absolute",
                right: 0,
                paddingRight: 0,
                paddingLeft: 0,
              }}
            >
              <GetReadySlider />
            </div>
            <div
              style={{
                background: "#FBFBFB",
                marginBottom: 100,
              }}
              className="container-fluid py-5"
            >
              <p
                style={{ fontWeight: "bold" }}
                className="h3 my-3 text-center d-block d-md-none"
              >
                <span style={{ display: "block" }}>
                  {t("GENERAL.uvpTitle1")}
                </span>
                <span style={{ color: "#F9C825", marginRight: 10 }}>
                  {t("GENERAL.uvpTitle2")}
                </span>
                <span className="d-block d-lg-inline">
                  {t("GENERAL.uvpTitle3")}
                </span>
                <span style={{ display: "block" }}>
                  <span style={{ color: "#F9C825" }}>
                    {t("GENERAL.uvpTitle4")}
                  </span>
                </span>
              </p>
              <div className="container-md">
                <div
                  className="row flex-column"
                  style={{
                    minHeight: 500,
                  }}
                >
                  <div className="col-md-12 col-lg-8">
                    <p
                      style={{ fontWeight: "bold" }}
                      className="h1 text-lg-left text-center d-none d-md-block"
                    >
                      <span style={{ display: "block" }}>
                        {t("GENERAL.uvpTitle1")}
                      </span>
                      <span style={{ color: "#F9C825", marginRight: 10 }}>
                        {t("GENERAL.uvpTitle2")}
                      </span>
                      <span className="d-block d-lg-inline">
                        {t("GENERAL.uvpTitle3")}
                      </span>
                      <span style={{ display: "block" }}>
                        <span style={{ color: "#F9C825" }}>
                          {t("GENERAL.uvpTitle4")}
                        </span>
                      </span>
                    </p>
                    <p
                      style={{
                        fontSize: 24,
                        lineHeight: "140%",
                        margin: "40px 0",
                      }}
                      className="text-center text-lg-left"
                    >
                      {t("GENERAL.uvpBody")}
                    </p>
                    <div
                      style={{ marginLeft: 0 }}
                      className="row justify-content-center justify-content-lg-start"
                    >
                      <button
                        style={{
                          marginRight: 10,
                          borderRadius: 42,
                          fontWeight: "bold",
                          lineHeight: "20px",
                          marginBottom: 10,
                          height: 50,
                          width: 250,
                        }}
                        type="button"
                        className="btn btn-primary CTA-secondary btn-lg d-none d-md-block"
                        onClick={handleQuoteRequest}
                      >
                        {t("GENERAL.uvpBtn1Text")}
                      </button>
                      <button
                        style={{
                          borderRadius: 42,
                          fontWeight: "bold",
                          lineHeight: "20px",
                          marginBottom: 10,
                          fontSize: 16,
                          height: 50,
                          width: 250,
                          minWidth: 250,
                        }}
                        type="button"
                        className="btn btn-primary btn-lg d-block d-md-none"
                        onClick={handleQuoteRequest}
                      >
                        {t("GENERAL.uvpBtn1Text")}
                      </button>
                      <div className="btn-group" role="group">
                        <button
                          type="button"
                          style={{
                            borderRadius: 42,
                            height: 50,
                            width: 250,
                            fontWeight: "bold",
                            color: "#000000",
                            background: "#E0E0E0",
                            border: "none",
                            lineHeight: "20px",
                            padding: "15px 32px",
                            fontSize: 16,
                          }}
                          className="btn btn-secondary dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {t("GENERAL.uvpBtn2Text")}
                        </button>
                        <div
                          style={{ marginTop: 20 }}
                          className="dropdown-menu"
                          aria-labelledby="btnGroupDrop1"
                        >
                          <Link
                            className="dropdown-item"
                            to={commercialUrl}
                            language={language}
                          >
                            {t("GENERAL.uvpDropDownBtn1Text")}
                          </Link>
                          <Link
                            className="dropdown-item"
                            to={wareHouseUrl}
                            language={language}
                          >
                            {t("GENERAL.uvpDropDownBtn2Text")}
                          </Link>
                          <Link
                            className="dropdown-item"
                            to={coatingUrl}
                            language={language}
                          >
                            {t("GENERAL.uvpDropDownBtn3Text")}
                          </Link>
                        </div>
                      </div>
                    </div>
                    <hr style={{ margin: "30px 0" }} />
                  </div>
                  <p
                    className="h5"
                    style={{
                      fontWeight: 600,
                      fontSize: "16px",
                      lineHeight: "126%",
                      marginBottom: "25px",
                    }}
                  >
                    {t("GENERAL.uvpCredentialsTagline")}
                  </p>
                  <div className="row">
                    <div className="col-md-8">
                      <div className="row justify-content-md-start justify-content-between">
                        {trustedCompanies.map((company) => (
                          <div key={company.alt} className="mx-4 my-3">
                            <img
                              alt={company.alt}
                              key={company.alt}
                              src={company.imageUrl}
                              style={{
                                maxHeight: company.maxHeight,
                                maxWidth: company.maxWidth,
                                width: "100%",
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <img
              style={{ position: "absolute", right: 0 }}
              alt="circle"
              className="d-none d-lg-block"
              src={circleSrc}
            /> */}
          </div>

          {/* // SERVICES BUTTONS */}
          <section>
            <div className="container-md services-section">
              <div
                className="row justify-content-center justify-content-sm-between"
                style={{
                  minHeight: "175px",
                  margin: "60px 0",
                }}
              >
                {servicesButtonsData.map((service) => (
                  <ServicesButton
                    anchor={service.anchor}
                    bgImage={service.bgImage}
                    btnText={service.btnText}
                    key={service.btnText}
                  />
                ))}
              </div>
            </div>
          </section>

          {/* HOW CAN WE HELP? */}
          <section>
            <div className="container-md">
              <div
                style={{ marginBottom: 40, marginTop: 20 }}
                className="row align-items-center"
              >
                <div className="col-md-4 d-flex justify-content-center">
                  <img alt="worker-img" src={workerSrc} />
                </div>
                <div className="col-md-6 container">
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "44px",
                      lineHeight: "55px",
                    }}
                    className="h1 text-center text-lg-left"
                  >
                    <span style={{ color: "#F9C825" }}>
                      {t("GENERAL.howCanWeHelpTitle1")}
                    </span>
                    {t("GENERAL.howCanWeHelpTitle2")}
                  </p>
                  <div style={{ maxWidth: 550 }}>
                    <p
                      className="text-center text-lg-left"
                      style={{ lineHeight: "28px" }}
                    >
                      {t("GENERAL.howCanWeHelpBody")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* // SERVICES CARDS */}
          <section>
            <div className="container-fluid">
              {/* <img
                style={{ position: "absolute", right: "10%" }}
                alt="shield-right"
                className="d-none d-lg-block"
                src={shieldRightSrc}
              /> */}
              <div className="container-md">
                <div className="row">
                  <div className="row justify-content-center justify-content-md-between">
                    {servicesCardData.map((service) => (
                      <ServicesCard
                        id={service.id}
                        key={service.id}
                        cardStyle={service.cardStyle}
                        imgSrc={service.imgSrc}
                        cardTitle={service.cardTitle}
                        cardBody={service.cardBody}
                        btnText={service.btnText}
                        btnUrl={service.btnUrl}
                        handleClick={service.handleClick}
                      />
                    ))}
                  </div>
                </div>
              </div>
              {/* <img
                style={{ position: "absolute", left: 0 }}
                alt="shield-left"
                className="d-none d-lg-block"
                src={shieldLeftSrc}
              /> */}
            </div>
          </section>

          {/* WE ARE TRUSTED */}
          <section>
            <div
              style={{
                marginTop: 60,
                borderBottom: "1px solid #E0E0E0",
                padding: "0 0 60px 0",
              }}
              className="container-md"
            >
              <div
                style={{ flexDirection: "column", alignItems: "center" }}
                className="row justify-content-center"
              >
                <p
                  className="h2"
                  style={{
                    textAlign: "center",
                    fontSize: "44px",
                    lineHeight: "55px",
                    fontWeight: "bold",
                    marginBottom: 25,
                  }}
                >
                  {t("GENERAL.weAreTrustedTitle1")}
                  <span style={{ color: "#F9C825" }}>
                    {t("GENERAL.weAreTrustedTitle2")}
                  </span>
                </p>
                <div style={{ marginBottom: 60 }} className="col-10 col-md-10">
                  <p
                    style={{
                      fontSize: "17px",
                      lineHeight: "28px",
                      textAlign: "center",
                    }}
                  >
                    {t("GENERAL.weAreTrustedBody")}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 justify-content-center mx-auto">
                  <DisinfectionSlider commercial />
                </div>
              </div>
            </div>
          </section>

          {/* ABOUT US */}
          <section>
            <div style={{ marginTop: 60 }} className="container-md">
              <div className="row">
                <div className="col-12">
                  <div className="row justify-content-center">
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "44px",
                        lineHeight: "55px",
                        fontWeight: "bold",
                      }}
                      className="h2"
                    >
                      {t("GENERAL.aboutUsTitle1")}
                      <span style={{ color: "#F9C825" }}>
                        {t("GENERAL.aboutUsTitle2")}
                      </span>
                    </p>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-12 col-md-10">
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "16px",
                          lineHeight: "28px",
                        }}
                      >
                        {t("GENERAL.aboutUsBody")}
                      </p>
                    </div>
                    <div className="container my-5 text-center">
                      <FeaturedInSlider />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
        <GetQuoteModal />
        <ThankYouModal />
      </div>
    </PageContainer>
  );
};

export default IndexPage;
