/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable global-require */
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./styles.css";

const data = [
  {
    image: require("../../images/general/get-ready-1.png"),
    caption: "get-ready-1",
  },
  {
    image: require("../../images/general/get-ready-2.png"),
    caption: "get-ready-2",
  },
  {
    image: require("../../images/general/get-ready-3.png"),
    caption: "get-ready-3",
  },
  {
    image: require("../../images/general/get-ready-4.png"),
    namcaptione: "get-ready-4",
  },
];

// eslint-disable-next-line react/prop-types
const LengendForm = ({ content = "", number = "01" }) => {
  return (
    <div className="slider-container">
      <div className="text-reverse">{content}</div>
      <div className="text-number">#{number}</div>
    </div>
  );
};

const GetReaderText = () => {
  return (
    <p className="h1 text-left text-ready">
      Get Ready for the <span className="d-block">New Normal</span>
    </p>
  );
};

const GetReadySlider = () => {
  return (
    <div>
      <Carousel
        showThumbs={false}
        showArrows={false}
        showStatus={false}
        autoPlay
        infiniteLoop
        transitionTime={500}
      >
        <div>
          <img src={data[0].image} alt="ready-1" />
          <LengendForm content="The New Normal" number="01" />
          <GetReaderText />
        </div>
        <div>
          <img src={data[1].image} alt="ready-2" />
          <LengendForm content="The New Normal" number="02" />
          <GetReaderText />
        </div>
        <div>
          <img src={data[2].image} alt="ready-3" />
          <LengendForm content="The New Normal" number="03" />
          <GetReaderText />
        </div>
        <div>
          <img src={data[3].image} alt="ready-4" />
          <LengendForm content="The New Normal" number="04" />
          <GetReaderText />
        </div>
      </Carousel>
    </div>
  );

};

export default GetReadySlider;
